import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/doc/fileSharing/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/doc/fileSharing/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/doc/fileSharing/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/doc/fileSharing/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/doc/fileSharing/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/doc/fileSharing/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/doc/fileSharing/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchEditOpen(idList){
  return request.post(constant.serverUrl + "/doc/fileSharing/batchEditOpen",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function batchEditClose(idList){
  return request.post(constant.serverUrl + "/doc/fileSharing/batchEditClose",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function findByCompanyId(formData){
  return request.post(constant.serverUrl + "/doc/fileSharing/findByCompanyId", formData);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,batchEditClose,batchEditOpen,findByCompanyId
}