<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/fileSharing">文件共享管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="标题" prop="title">
        <el-input type="text" size="mini" v-model="queryModel.title"></el-input>
      </el-form-item>
      <el-form-item label="文件目录" prop="type">
        <el-select
          clearable
          v-model="queryModel.type"
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option
            v-for="result in typeResult"
            :key="result.value"
            :label="result.name"
            :value="result.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          clearable
          v-model="queryModel.status"
          filterable
          placeholder="请选择"
          style="width: 200px"
          size="mini"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="共享" value="1"></el-option>
          <el-option label="关闭" value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="batchEditOpen"
        >批量开启共享</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="batchEditClose"
        >批量关闭共享</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="typeN"
        label="文件目录"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="文件标题"
        width="350"
        show-overflow-tooltip
        ><template slot-scope="{ row }">
          <a :href="row.fileUrl" target="_blank">
            {{ row.title }}
          </a>
        </template></el-table-column
      >
      <el-table-column
        prop="statusN"
        label="状态"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="sortNo"
        label="排序号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="上传时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <fileSharing-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      :personResult = "personResult"
      @close="onDetailModalClose"
    ></fileSharing-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import FileSharingDetail from "./fileSharing-detail";
import fileSharingApi from "@/api/doc/fileSharing";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        title: "",
        status: "",
        type: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeResult: [],
      personResult:[],
    };
  },
  created() {
    var self = this;

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "文件目录",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeResult = jsonData.data;
      });

    var formData = new FormData();

    formData.append("companyId", "ff7f47c0-3c32-460c-b9e8-352415214");
    fileSharingApi.findByCompanyId(formData).then((response) => {
      var jsonData = response.data;
      this.personResult = jsonData.data;
    });
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("title", self.queryModel.title);
      formData.append("status", self.queryModel.status);
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      fileSharingApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          fileSharingApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fileSharingApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    batchEditOpen() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认修改选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fileSharingApi.batchEditOpen(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "修改成功!",
            });
          }
        });
      });
    },
    batchEditClose() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认修改选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fileSharingApi.batchEditClose(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "修改成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "fileSharing-detail": FileSharingDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>