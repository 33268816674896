
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formModel.title"
            placeholder="请输入文件标题"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件目录" prop="type">
          <el-select
            clearable
            v-model="formModel.type"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="mini"
          >
            <el-option
              v-for="result in typeResult"
              :key="result.id"
              :label="result.name"
              :value="result.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件" prop="fileUrl">
          <el-upload
            class="upload-demo"
            name="photoFile"
            :action="uploadUrl"
            :headers="headers"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传1个文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否共享" prop="status">
          <el-radio-group v-model="formModel.status">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="共享人员" prop="personIds">
          <el-select
            v-model="formModel.personIds"
            filterable
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="person in personResult"
              :key="person.id+''"
              :label="person.name"
              :value="person.id+''"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input-number
            v-model="formModel.sortNo"
            :min="1"
            style="width: 300px"
          ></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import fileSharingApi from "@/api/doc/fileSharing";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title", "personResult"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        type: [{ required: true, message: "请选择文件目录", trigger: "blur" }],
        fileUrl: [{ required: true, message: "请上传附件", trigger: "blur" }],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      fileList: [],
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadFile",
      uploadData: {
        subFolder: "personInfo",
      },
      headers: {
        Authorization: getToken(),
      },
      file: {
        name: "",
        url: "",
      },
      typeResult: [],
    };
  },
  created() {
    var self = this;

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "文件目录",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeResult = jsonData.data;
      });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (
              self.formModel.personIds != null &&
              self.formModel.personIds != ""
            ) {
              var personIds = self.formModel.personIds.join(",");
              self.formModel.personIds = personIds;
            } else {
              self.formModel.personIds = "";
            }

            if (id == null || id.length == 0) {
              return fileSharingApi.add(self.formModel);
            } else {
              return fileSharingApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.fileName = res.data.name;
      self.formModel.fileUrl = res.data.url;
      if (self.formModel.title == "" || self.formModel.title == null) {
        self.formModel.title = res.data.name;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return fileSharingApi.create();
      } else {
        return fileSharingApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (self.formModel.fileUrl != null) {
            self.file.name = self.formModel.fileName;
            self.file.url = self.formModel.fileUrl;
            self.fileList.push(self.file);
          }

          var personIds = self.formModel.personIds;
          if (personIds != "") {
            self.formModel.personIds = personIds.split(",");
          }

        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>